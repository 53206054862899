var arr = [
  {
    title: 'اجهزة البصمة',
    route: 'home',
    icon: 'HardDriveIcon',
  },
  {
    title: 'الطلبات السابقة',
    route: 'orders',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'الدعم الفني',
    href: 'https://tahdir.net/support',
    icon: 'HeadphonesIcon',
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon',
  },
], arr2 = [];

arr.forEach(function (e) {

  if (e.icon == 'LogOutIcon' && localStorage.getItem('user')) {
    arr2.push(e)
  } else {
    if (e.icon != 'LogOutIcon') {
      arr2.push(e)
    }
  }

})


export default arr2
